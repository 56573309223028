#register-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .submit-buttons {
    display: flex;
    justify-content: space-between;
  }

  .success-message {
    .MuiAlert-icon {
      display: flex;
      align-items: center;
    }

    .MuiAlert-message {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.paper-wrapper {
  padding: 25px;
  height: auto;
  width: 350px;
  margin: 20vh auto;
}