.MuiTypography-root {
    font-family: 'Source Sans Pro', sans-serif;
}

#host-page, #instances-page, #rsa-key-page, #system-users-page, #domains-page, #domains-page {
    .box-main {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .box-list {
        background-color: rgb(31, 31, 31);
        border-radius: 5px;
        border-color: rgb(63, 63, 63);
        border-style: dotted;
        border-width: 1px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .loading-screen, .empty-table {
        .box-list-table {
            .css-axafay-MuiDataGrid-virtualScroller {
                min-height: 300px;
                overflow: hidden;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .box-main {
            flex-direction: column;
            align-items: center;
        }
    }
}
