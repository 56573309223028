#layout {
  display: flex;

  .MuiTypography-root {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .layout-sidebar-wrapper {
    position: fixed;

    .MuiDrawer-paper {
      position: inherit;
      height: 100vh;
    }
  }

  #page-content-wrapper {
    margin-left: 250px;
    overflow: auto;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    #page-content-wrapper {
      margin-left: 0;
    }

    .layout-sidebar-wrapper {
      z-index: 10;
      .MuiDrawer-paper:before {
        content: '≡';
        font-size: 40px;
        position: fixed;
        left: 0;
        z-index: 10;
        background: rgba(31, 31, 31, 0.8);
        padding: 30px 10px;
        border-radius: 0 10rem 10rem 0;
        transition: all 0.5s;
        cursor: pointer;
      }
    }

    .hide-sidebar {
      width: 0;
      position: fixed;
      transition: all 0.5s;

      .MuiDrawer-paper {
        width: 0;
        position: fixed;
        transition: all 0.5s;
      }
    }

    .show-sidebar {
      width: 250px;
      position: fixed;
      transition: all 0.5s;

      .MuiDrawer-paper {
        width: 250px;
        position: fixed;
        transition: all 0.5s;
      }

      .MuiDrawer-paper:before {
        left: 250px;
        transition: all 0.5s;
      }
    }
  }
}