body, html {
  height: 100%;
}

#login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  height: 100%;

  .avatar {
    background-color: #1bbd7e;
  }

  .input-field {
    background-color: #222222;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
    border-radius: 4px;
    padding: 0;
    min-width: 300px;
  }
  
  .submit-button {
    display: flex;
    justify-content: flex-end;

    button {
      min-width: 300px;
      margin: 8px 0;
    }
  }
}
