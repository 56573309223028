#sidebar {
    .menu-box {
      background-color: rgb(20, 20, 20);
      border-radius: 5px;
      border-color: rgb(63, 63, 63);
      border-style: dotted;
      border-width: 1px;
      margin: 10px;
    }
  
    .menu-background {
      background-color: rgb(10, 10, 10);
    }
  
    .MuiPaper-root  {
      background: rgb(31, 31, 31);
    }
  
    .MuiDivider-root {
      margin-inline: 10px;
    }
  }