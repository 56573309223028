#home-page {
  .paper-last-modified-wrapper {
    width: 300px;
    padding: 15px;
    border-radius: 5px;
    overflow-y: hidden;

    .list-container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      background-color: rgb(20, 20, 20);
      height: 30vh;
      overflow-y: auto;

      .list-element {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h6 {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .list-container::-webkit-scrollbar {
      display: none;
    }

    .list-button-link {
      a {
        display: flex;
        justify-content: center;
        text-decoration: none;
        color: inherit;

        svg {
          margin-left: 10px;
        }
      }
    }

    .no-rows {
      height: -webkit-fill-available;
      justify-content: center;
    }

    .loading-screen {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}